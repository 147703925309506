import React from 'react';

import ServicesColumn from '../services/ServicesColumn';

const HomeServices = () => {
    return (
        <section id="cennik" className="block bg-gray-light spacer p-top-xl p-bottom-md">
            <div className="wrapper">
                <div className="title">
                    <h2>Oferta i cennik usług stomatologicznych</h2>
                </div>

                <div className="description">
                    <p>Ważny jest dla nas Państwa komfort, z tego względu znieczulenie miejscowe wykonujemy bez dodatkowych opłat.</p>
                {/*</div>*/}

                {/*<div className="row gutter-width-md with-pb-xs spacer m-top-xs">*/}
                    {/*<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">*/}
                    {/*    <ServicesColumn first={true} />*/}
                    {/*</div>*/}
                    {/*<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">*/}
                    {/*    <ServicesColumn first={false} />*/}
                    {/*</div>*/}
                    <table className="table table-striped">
                        <tbody>
                        <tr>
                            <td scope="row">Wypełnienie w zębie stałym</td>
                            <td>od&nbsp;250&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Wypełnienie w zębie mlecznym</td>
                            <td>od&nbsp;150&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Wizyta adaptacyjna dziecka</td>
                            <td>100&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Leczenie endodontyczne</td>
                            <td>od&nbsp;450&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Powtórne leczenie endodontyczne</td>
                            <td>od&nbsp;800&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Odbudowa zęba na wkładzie z włókna szklanego</td>
                            <td>650&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Higienizacja (skaling, piaskowanie, fluoryzacja)</td>
                            <td>300&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Ekstrakcja zęba stałego</td>
                            <td>od&nbsp;200&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">Ekstrakcja zęba mlecznego</td>
                            <td>od&nbsp;100&nbsp;zł</td>
                        </tr>
                        <tr>
                            <td scope="row">wybielanie nakładkowe</td>
                            <td>900&nbsp;zł</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="description-bottom spacer p-top-sm">
                    <p>Aby przeprowadzić kompleksowe leczenie o dobrym efekcie niekiedy konieczne jest połączenie kilku procedur,
                        dlatego o całkowitym koszcie leczenie poinformuje Państwa lekarz po wstępnym badaniu, jeszcze przed rozpoczęciem leczenia.</p>
                    <p>Cennik nie stanowi oferty handlowej w rozumieniu art. 66 par. 1 Kodeksu Cywilnego.</p>
                </div>
            </div>
        </section>
    );
};

export default HomeServices;
